 // Making HTTP request - (for JSON file)
 // using fetch() with async and await, fetch has Promise built-in. Auto checks readyState, so no need to check in 'if'
// 'return data.puzzle;' resolve to then() in app.js)
 const getPuzzle = async (wordCount) => {
     // leave out protocal 'http:, https:' ensures works fine on any env
     const response = await fetch(`//puzzle.mead.io/puzzle?wordCount=${wordCount}`, {})
    
     if (response.status === 200) {
        const data = await response.json(); // awaits until response resolve 
        return data.puzzle;
    } else {
        throw new Error(`Unable to get Puzzle`);
    }
};


export { getPuzzle as default };


