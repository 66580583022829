import Hangman from './hangman';
import getPuzzle from './request';


const puzzleEl =  document.querySelector('#puzzle');
const guessesEL = document.querySelector('#guesses');
let gameOne

// initial display puzzle
const render = () => {
    puzzleEl.innerHTML = '';
    guessesEL.textContent = gameOne.statusMessage;
    
    // parsing each character of puzzle in span tag - *could be
		// styled easy via css without splitting.  
    gameOne.puzzle.split('').forEach((letter) => {
      const letterEl = document.createElement('span');
      letterEl.textContent = letter;
      puzzleEl.appendChild(letterEl);
    })
}


// use keyboard to make a guess
window.addEventListener('keypress', (e) => {
  const guess = String.fromCharCode(e.charCode); // get access to charcode 
  gameOne.makeGuess(guess); // parses the key pressed
  render(); // re-render puzzle after a guess
})

// create new game. Puzzle from http promise request
const startGame = async () => {
  const puzzle = await getPuzzle('2'); // get new puzzle owrd - HTTP Promise 
  gameOne = new Hangman(puzzle, 5); // create new game, parse puzzle word to it. 5 allowed guesses
  render(); // display puzzle
}

document.querySelector('#reset').addEventListener('click', startGame)

startGame() 

