class Hangman {
  constructor (word = [], remainingGuesses) {
    this.word = word.toLowerCase().split('');
    this.remainingGuesses = remainingGuesses;
    this.guessedLetters = [];
    this.status = 'Playing'
  }

  // check status of the game
  getStatus() {
    // check if guessedletters match the word  
    const hasFinished = this.word.every((letter) => this.guessedLetters.includes(letter) || letter == ' ');
    const failed = this.remainingGuesses === 0;

    if (failed) {    
      this.status = 'Failed';
    } else if (hasFinished) {
      this.status = 'Finished';
    } else {
      this.status = 'Playing'
    }
  }

  // print status message
  get statusMessage() {
    let statusMessage = '';
    if (this.status === 'Failed') {
      statusMessage = `Nice try! The word was "${this.word.join('')}"`;
    } else if (this.status === 'Finished') {
      statusMessage = `Great work! You guessed the word.`;
    } else {
      statusMessage = `Guesses left: ${this.remainingGuesses}`;
    }
    return statusMessage;
  }

  // setting methods to get puzzle
 get puzzle() {
    let puzzle = '';
     
    this.word.forEach((letter) => {
      if (this.guessedLetters.includes(letter) || letter === ' ') {
        puzzle += letter;
      } else {
        puzzle += '*';
      }                  
    }); 

    return `${puzzle}`;
  }
 

  // validating guessed letter and calculating remaining guesses
  makeGuess(guess) {
    guess = guess.toLowerCase();
    const isUnique = !this.guessedLetters.includes(guess); // check letter is not already guessed
    const isBadGuess = !this.word.includes(guess); // check if word doesn't contain the guessed letter
  
    // do nothing if status not eq 'Playing'
    if (this.status !== 'Playing') {
      return // stop code below from running.
    }
  
    if (isUnique) {
      this.guessedLetters = [...this.guessedLetters, guess]; // add only unique letters guesses via spread

      if (isBadGuess) {      
        this.remainingGuesses--; // decrement, if unique guessed letter, but not matched
      }
    } 
    this.getStatus(); // checks and sets status 
  }

};

export { Hangman as default };



